import { faChevronDown, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, notification, Row, Select, Table } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import client from '../../../api/client'

const sectionStats = [
  { title: 'Илгээмжүүд', dataIndex: 'orderStats' },
  { title: 'Богц', dataIndex: 'shipmentStats' },
]

const StatusBoxStyle = {
  backgroundColor: '#fff',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 8,
  padding: '10px 30px',
  marginRight: 10,
}

const subMenus = [
  {
    name: 'Ерөнхий',
    dataIndex: 'brief',
  },
  {
    name: 'Төлбөрийн дэлгэрэнгүй',
    dataIndex: 'orders-summary',
  },
]
const Shipments = () => {
  const [orderStats, setOrderStats] = useState(null)
  const [activeSubMenu, setActiveSubMenu] = useState('brief')
  const [loading, setLoading] = useState(true)
  const [selectedBranch, setSelectedBranch] = useState(1)
  const [branchList, setBranchList] = useState([])

  const [ordersSummaryPaginatedData, setOrdersSummaryPaginatedData] = useState({
    search: '',
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sorter: {
      field: 'date',
      order: 'descend',
    },
    branchId: selectedBranch,
  })
  const [ordersSummaryInfo, setOrdersSummaryInfo] = useState({
    tableData: [],
    dataCount: 0,
  })
  const ordersSummaryColumns = [
    {
      title: '',
      children: [
        {
          title: 'Огноо',
          dataIndex: 'date',
          className: 'text-center',
        },
      ],
    },
    {
      title: 'Агаарын илгээмж',
      align: 'left',
      children: [
        {
          title: 'Жин',
          dataIndex: 'airWeight',
          className: 'text-center',
        },
        {
          title: 'АНУ-д авсан',
          dataIndex: 'airAmountUSA',
          className: 'text-center',
        },
        {
          title: 'Монголд авсан',
          dataIndex: 'airAmountMGL',
          className: 'text-center',
        },
      ],
    },
    {
      title: 'Газрын илгээмж',
      align: 'left',
      children: [
        {
          title: 'Тоо',
          dataIndex: 'groundQuantity',
          className: 'text-center',
        },
        {
          title: 'АНУ-д авсан',
          dataIndex: 'groundAmountUSA',
          className: 'text-center',
        },
        {
          title: 'Монголд авсан',
          dataIndex: 'groundAmountMGL',
          className: 'text-center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: 'Нийт төлбөр',
          dataIndex: 'totalAmount',
          className: 'text-center',
        },
      ],
    },
    {
      title: '',
      children: [
        {
          title: 'Үйлдэл',
          className: 'text-center',
          render: (record) => {
            return (
              <Button
                className="w-100 btn-sb btn-sb-eye bg-base"
                onClick={() => downloadReport(record.branchId, moment(record.date).format('MM-DD-YYYY'))}
              >
                <FontAwesomeIcon icon={faPrint} size="xs" />
              </Button>
            )
          },
        },
      ],
    },
  ]
  const reOrganizeData = (list) => {
    const organizedData = []
    list.forEach((each) => {
      organizedData.push({
        branchId: each.branchId,
        date: moment(each.date).format('MM/DD/YYYY'),
        airAmountUSA: `$${each.airShipment.amount.inUS}`,
        airAmountMGL: `$${each.airShipment.amount.inMongolia}`,
        airWeight: `${each.airShipment.weight.toFixed(2)} кг`,
        groundAmountUSA: `$${each.groundShipment.amount.inUS}`,
        groundAmountMGL: `$${each.groundShipment.amount.inMongolia}`,
        groundQuantity: each.groundShipment.quantity,
        totalAmount: `$${each.totalAmount.inMongolia + each.totalAmount.inUS}`,
      })
    })
    if (organizedData.length === list.length) {
      setLoading(false)
      return organizedData
    }
  }
  useEffect(() => {
    const fetchOrderStats = async () => {
      const endpoint = `/api/orders/stats`

      await client
        .get(endpoint)
        .then((res) => {
          setOrderStats(res.data)
        })
        .catch((err) => console.error({ err }))
    }
    fetchOrderStats()
  }, [])
  const fetchOrdersSummary = useCallback(() => {
    client
      .post(`/api/orders/summary`, { ...ordersSummaryPaginatedData })
      .then(async (res) => {
        const listData = reOrganizeData(res.data.data)
        await setOrdersSummaryInfo({
          tableData: listData,
          dataCount: res.data.total,
        })
      })
      .catch((error) => console.error(error))
  }, [ordersSummaryPaginatedData])
  useEffect(() => {
    const fetchBranchList = () => {
      client
        .get(`/api/lookup/branches/account`)
        .then(async (res) => {
          setBranchList(res.data.map((branch) => ({ value: branch.id, label: branch.name })))
        })
        .catch((error) => console.error(error))
    }
    fetchBranchList()
    fetchOrdersSummary()
  }, [fetchOrdersSummary])
  const downloadReport = (branchId, date) => {
    client
      .get(`/api/orders/branches/${branchId}/summary/${date}`, { responseType: 'blob' })
      .then(async (res) => {
        const blob = new Blob([res.data], { type: res.headers['content-type'] })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `SBExpress_OrderSummary_${branchId}_${date}.xlsx`)
        document.body.appendChild(link)
        link.click()
      })
      .catch((err) => {
        notification.error({
          message: 'Тайлан татаж авахад асуудал гарлаа',
          description: `${err}`,
        })
        console.error(err)
      })
  }

  return (
    <>
      <Helmet title="Тайлан" defer={false} />
      <Row gutter={[8, 8]} className="mt-4" style={{ minHeight: 50 }}>
        {subMenus.map((subMenu) => {
          return (
            <Col key={subMenu.dataIndex} className="me-5">
              <Button
                type="link"
                className="px-0"
                onClick={async () => await setActiveSubMenu(subMenu.dataIndex)}
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  color: activeSubMenu === subMenu.dataIndex ? '#11427F' : '#333333',
                  borderBottom: activeSubMenu === subMenu.dataIndex ? '1px solid #11427F' : '',
                }}
              >
                {subMenu.name}
              </Button>
            </Col>
          )
        })}
      </Row>
      <>
        {activeSubMenu === 'brief' ? (
          orderStats ? (
            <Row gutter={[24, 8]} className="mt-4 mx-5">
              {sectionStats.map((section) => {
                let sectionData = orderStats[section.dataIndex]
                return (
                  <Col span={7} key={section.dataIndex} style={StatusBoxStyle}>
                    <h2 className="mb-5">{section.title}</h2>
                    {Object.keys(sectionData)?.map((stat, statIdx) => {
                      return (
                        <Row gutter={[8, 8]} key={statIdx} className="mb-2" style={{ fontSize: 18 }}>
                          <Col flex="auto">{stat}</Col>
                          <Col flex="25px">{sectionData[stat]}</Col>
                        </Row>
                      )
                    })}
                  </Col>
                )
              })}
            </Row>
          ) : (
            ''
          )
        ) : activeSubMenu === 'orders-summary' ? (
          <>
            <Row className="sb-table-header" gutter={[8, 8]}>
              <Col flex="auto">
                <span className="sb-text-medium">Төлбөрийн дэлгэрэнгүй</span>
              </Col>
              <Col flex="250px">
                <Select
                  placeholder="Салбарууд"
                  className="w-100 sb-shadow sb-select"
                  popupClassName="sb-select-dropdown"
                  optionFilterProp="children"
                  suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" style={{ color: '#11427f' }} />}
                  options={branchList}
                  defaultValue={selectedBranch === Int32Array ? String(selectedBranch) : []}
                  onChange={(branchId) => {
                    setSelectedBranch(branchId)
                    setLoading(true)
                    setOrdersSummaryPaginatedData({
                      ...ordersSummaryPaginatedData,
                      branchId,
                      pagination: {
                        ...ordersSummaryPaginatedData.pagination,
                        current: 1,
                      },
                    })
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  className="sb-table"
                  columns={ordersSummaryColumns}
                  dataSource={branchList.length <= 3 ? (ordersSummaryInfo.tableData = branchList.tableData) : ordersSummaryInfo.tableData}
                  rowKey={(record) => record.id}
                  locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
                  loading={loading}
                  onChange={(pagination, filter, sorter) => {
                    setLoading(true)
                    if (sorter.field)
                      setOrdersSummaryPaginatedData({
                        ...ordersSummaryPaginatedData,
                        pagination: {
                          current: pagination.current,
                          pageSize: pagination.pageSize,
                        },
                        sorter: {
                          field: sorter.field,
                          order: sorter.order,
                        },
                      })
                    else
                      setOrdersSummaryPaginatedData({
                        ...ordersSummaryPaginatedData,
                        pagination: {
                          current: pagination.current,
                          pageSize: pagination.pageSize,
                        },
                      })
                  }}
                  pagination={{
                    total: ordersSummaryInfo.dataCount,
                    current: ordersSummaryPaginatedData.pagination.current,
                    pageSize: ordersSummaryPaginatedData.pagination.pageSize,
                  }}
                />
              </Col>
            </Row>
          </>
        ) : (
          ''
        )}
      </>
    </>
  )
}

export default Shipments
